import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes'

import store from '../store';
import { SET_SECTION } from '../store/user/mutations.type'
import { getInfoUser } from '@/services/auth/auth.service';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to:any, from:any, next:any) => {
  // в Хроме при отладке store не выводится, но он есть
  const publicPages = ['Login', 'login_new', 'register', 'ForgotPassword', 'ResetPassword', 'authorize'];
  const authRequired = !publicPages.includes(to.name);
  const resetPas = location.pathname;
  const loggedIn = localStorage.getItem('authorization') === 'true';
  const token = localStorage.getItem('token');

  // если переходим по ссылке на какой то объект не войдя в систему, то сохраним путь и после логина перейдем
  if (!token && to.name != 'Login' && authRequired) {
    sessionStorage.setItem("goto", to.path)
  }
  
  if (token && !store.getters.userData) {
    const user = await getInfoUser(token)
    if (user) console.log('User was set in beforeEach. is SA ?', store.getters.userIsSuperAdmin);
  }

  const {userIsSuperAdmin: isSA, userIsAdmin: isAdmin } = store.getters
  
  // чтобы простой юзер не зашел в клиентов
  if (to.path == '/clients' && !isSA) {
    return next({ name: 'company' });
  }

  if (authRequired && (!token || !loggedIn) && !resetPas.includes('reset-password')) {
    return next({ name: 'Login' });
  }

  if (to.name == 'employees' && !isAdmin) {
    return next({ name: 'company' }); // защита от перехода на админскую страницу
  }

  // if (!authRequired && token) {
  //   console.log(`you was redirected to company wall`);
  //   return next({ name: 'company' });
  // }
  

  // редирект дефолтной страницы
  if (to.path == '/') {
    const start = localStorage.getItem('start_page') || 'company'
    return next(isSA ? 'clients' : start);
  }

  store.commit(SET_SECTION, to.path.split("/")[1])

  next();
});

export default router
