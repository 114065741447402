import * as GroupMutations from '@/store/group/mutations.type';
import * as Actions from '@/store/project/actions.type';
import * as Mutations from '@/store/project/mutations.type';
import * as Services from '@/services/project.service';

interface State {
  projects: any[];
  currentProject: any;
}

const store: State = {
  projects: [],
  currentProject: null,
};

const getters = {
  projects(state: State) {
    return state.projects.map((e) => {
      e.participants = [...e.participants.sort((a: any, b: any) => {
        if (a._id === e.creatorId) {
          return -1;
        }
        if (b._id === e.creatorId) {
          return 1;
        }
        return 0;
      })];
      return e;
    });
  },
  currentProject(state: State) {
    return state.currentProject;
  },
};

const actions = {
  [Actions.CREATE_PROJECT]: Services.createProject,
  [Actions.GET_PROJECTS]: Services.getProjects,
  [Actions.GET_CURRENT_PROJECT]: Services.getCurrentProject,
  [Actions.DELETE_PROJECT]: Services.deleteProject,
  [Actions.EDIT_PROJECT]: Services.editProject,
  [Actions.CREATE_PROJECT_PARTICIPANT]: Services.createProjectParticipant,
};

const mutations = {
  [Mutations.ADD_PROJECT](state: State, project: any) {
    state.projects = [project, ...state.projects];
  },
  [Mutations.SET_PROJECTS](state: State, projects: any) {
    state.projects = projects;
  },
  [Mutations.SET_CURRENT_PROJECT](state: State, project: any) {
    state.currentProject = project;
  },
  [Mutations.REMOVE_PROJECT](state: State, projectId: any) {
    if (state.projects) {
      const index = state.projects.findIndex(({ _id }) => _id === projectId);
      state.projects = [
        ...state.projects.slice(0, index),
        ...state.projects.slice(index + 1),
      ];
    }
  },
  [GroupMutations.UPDATE_GROUP](state: State, project: any) {
    state.currentProject = project;
    if (state.projects) {
      const index = state.projects.findIndex(({ _id }) => _id === project._id);
      state.projects = [
        ...state.projects.slice(0, index),
        project,
        ...state.projects.slice(index + 1),
      ];
    }
  },
};

export default {
  state: store,
  getters,
  actions,
  mutations,
};
