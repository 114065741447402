import Vue from 'vue';

import * as Mutations from './mutations.type'

import { headers } from './../common/common.service'

export const getStructure = async (context: any, workspace: String) => {
  //context.commit(SET_STRUCTURE, {});
  const {data} = await Vue.axios.get('api/structure/byClient/' + workspace);
  context.commit(Mutations.SET_STRUCTURE, data.result);
}

export const saveStructure = async (context: any, structure: any) => {
  const {data} = await Vue.axios.post(`api/structure`, structure)
  context.commit(Mutations.SET_STRUCTURE, data.result);
  
}

export const editStructure = async (context: any, structure: any) => {
  const {data} = await Vue.axios.put(`api/structure/${structure._id}`, structure)
  context.commit(Mutations.SET_STRUCTURE, data.result);
  return data.result
}

//// действия со списком сотрудников отдела

// получение сотрудников всех отделов, отбор по клиенту
export const getDepartmentUsers = async (context: any, clientId: string) => {
  const {data} = await Vue.axios.get(`api/structure/byClient/${clientId}/dept_users`);
  context.commit(Mutations.SET_DEPT_USERS, data.result);
  return data.result
}

// сохранение сотрудников одного отдела (добавление)
export const saveUsersOfDepartment = async (context: any, data: any) => {
  const {data: dept_users} = await Vue.axios.post(`api/dept_users/${data.client_id}/${data.dept_id}`, { users: data.users })
  context.commit(Mutations.UPDATE_DEPT_USER, dept_users);
  // await getDepartmentUsers(context, data.client_id);
}

// Изменение сотрудника
export const editUserOfDepartment = async (context: any, data: any) => {
  // console.log('EDIT empl data:', JSON.stringify(data, null, 2));
  const response = await Vue.axios.put(`api/dept_users`, data) // PUT именно для установки руководителя используется
  context.commit(Mutations.UPDATE_DEPT_USER, response.data);
  getDepartmentUsers(context, data.client_id);
}

type DeptUserDelReq = {
  client_id: string,
  dept_id: string,
  user_id: string,
}

export const delUserOfDepartment = async (context: any, data: DeptUserDelReq) => {
  const response = await Vue.axios.delete(`api/dept_users/${data.dept_id}/${data.user_id}`, headers(data))
  context.commit(Mutations.REMOVE_DEPT_USER, response.data);
  getDepartmentUsers(context, data.client_id);
};

export const clearHeadOfDepartaments = (context: any, userId: string) => {
  return Vue.axios
    .put(`api/dept_users/clear_chief/${userId}`)
};