// import actions
import * as Actions from './actions.type'

// import mutations
import * as Mutations from './mutations.type'

// import service functions
import * as Services from './client.service';

type Feature = {
  _id?: string,
  feature: string,
  active: boolean
}

type Thank = {
  _id: string,
  googleImage: string,
  total: number,
  fullName: string,
}


type User = {
  _id: string,
  firstName: string,
  lastName: string,
  birthDate: string,
  googleImage: string | undefined,
}
type BirthdaysRes = {
  today: User[],
  next: {
    format: string,
    users: User[]
  }[]
}
interface Client {
  _id: any,
  workspace: string, // текстовый идентификатор клиента
  name: string,   // Полное наименование компании
  city: string,   // город компании
  address: string, // Адрес компании
  phone: string,   // основной телефон компании
  director: string, // директор компании
  logo: string,       // путь к логотипу компании
  colors: string[],  // массив корпоративных цветов,
  has_access: boolean, // статус активного доступа к функционалу
  comment: string,
  features: Feature[], // список всех фич
}

interface NotifTemplate {
  _id: string,
  client_id: string,
  channel: string,
  code: string,
  name: string,
  template: {
    ru: string,
    ua: string,
    en: string,
  }
}

interface State {
  clients: Client[],
  client: Client | undefined,
  currentClient: Client | undefined,
  clientLogged: string,
  client_statistic: object | null,
  dialog_message: string,
  features: Feature[],
  hangedFiles: object[],
  journal: object[],
  thanks?: Thank[],
  processing: boolean,
  filesStat: number, // занимаемое место всех файлов
  birthdays: BirthdaysRes | null,
  notif_templates: NotifTemplate[],
  vendor: string,  // yandex/google
}

const store: State = {
  clients: [],
  client: undefined,
  currentClient: undefined,
  clientLogged: "",
  client_statistic: null,
  dialog_message: "",
  features: [],
  hangedFiles: [],
  journal: [],
  thanks: [],
  processing: false,
  filesStat: 0,
  birthdays: null,
  notif_templates: [],
  vendor: '',
}

const getters = {
  clientFeatureFlags(state: State) {
    return state?.currentClient?.features.reduce((acc, f)=> ({...acc, [f.feature]: f.active}), {}) || {}
  },
  apiURL(state: State) {
    return process.env.NODE_ENV == 'development' ? 'http://localhost:4000' : location.origin
  },
  client(state: State) {
    return state.client
  },
  clients(state: State) {
    return state.clients;
  },
  currentClient(state: State) {
    return state.currentClient
  },
  client_statistic(state: State) {
    return state.client_statistic
  },
  dialog_message(state: State) {
    return state.dialog_message
  },
  features(state: State) {
    return state.features
  },
  hangedFiles(state: State) {
    return state.hangedFiles
  },
  journal(state: State) {
    return state.journal
  }
}

const mutations = {
  [Mutations.SET_DIALOG_MESSAGE](state: State, text: string) {
    state.dialog_message = text
  },
  [Mutations.SET_CLIENTS](state: State, clients: Client[]) {
    state.clients = clients
  },
  [Mutations.SET_CLIENT](state: State, client: Client) {
    state.client = client
    if (client && state.currentClient?._id == client._id) state.currentClient = client
  },
  [Mutations.SET_CURRENT_CLIENT](state: State, client: Client | undefined) {
    // console.log(`client ws set to ${client?.name}`);
    state.currentClient = client
  },
  [Mutations.UPDATE_CLIENT](state: State, client: Client) {
    const index: number = state.clients.findIndex((cl: Client) => cl._id == client._id)
    if (index != -1) {
      state.clients?.splice(index, 1, client)
    }
    if (state.currentClient?._id == client._id) {
      state.currentClient = client
    }
  },
  [Mutations.ADD_CLIENT](state: State, client: Client) {
    state.clients = [...state.clients, client]
  },
  [Mutations.REMOVE_CLIENT](state: State, workspace: string ) {
    state.clients = state.clients.filter(cl => cl.workspace != workspace)
  },
  [Mutations.SET_LOGGED_CLIENT](state: State, client_id: string) {
    state.clientLogged = client_id
  },
  [Mutations.SET_STATISTIC](state: State, data: object) {
    state.client_statistic = data
  },
  [Mutations.SET_FILES_STAT](state: State, data: number) {
    state.filesStat = data
  },
  [Mutations.SET_FEATURES](state: State, data: Feature[]) {
    state.features = data
  },
  [Mutations.SET_FEATURE](state: State, data: { client_id: string, features: Feature[] }) {
    // ищем клиента и фичу в его фичах
    const client = state.clients.find(c => c._id == data.client_id)
    if (client == undefined) return

    // если фича есть - меняем ее
    client.features = data.features

    if (state.currentClient?._id == client?._id) {
      state.currentClient = client
    }
  
  },
  [Mutations.SET_HANGED_FILES](state: State, data: object[]) {
    state.hangedFiles = data
  },
  [Mutations.SET_JOURNAL](state: State, data: object[]) {
    state.journal = data
  },
  [Mutations.ADD_JOURNAL_REC](state: State, data: object){
    state.journal = [data, ...state.journal]
  },
  [Mutations.SET_THANKS](state: State, data: Thank[]) {
    state.thanks = data
  },
  [Mutations.SET_PROCESSING](state: State, data: boolean) {
    state.processing = data
  },
  [Mutations.SET_BDAYS](state: State, data: BirthdaysRes) {
    state.birthdays = data
  },
  [Mutations.SET_NOTIF_TEMPLATES](state: State, data: any) {
    state.notif_templates = data
  },
  [Mutations.UPD_NOTIF_TEMPLATE](state: State, notif_template: NotifTemplate) {
    if (!state.notif_templates.find(t => t.code == notif_template.code)) 
      state.notif_templates.push(notif_template)
    else
      state.notif_templates = state.notif_templates?.map(nt => nt._id == notif_template._id ? notif_template : nt)
  },
  [Mutations.REMOVE_NOTIF_TEMPLATE](state: State, notif_id: string) {
    state.notif_templates = state.notif_templates.filter(nt => nt._id != notif_id)
  }
}

const actions = {
  [Actions.GET_CLIENTS]: Services.getClients,
  [Actions.GET_CLIENT]: Services.getClient,
  [Actions.CLEAR_CLIENT]: Services.clearClient,
  [Actions.CREATE_CLIENT]: Services.createClient,
  [Actions.EDIT_CLIENT]: Services.editClient,
  [Actions.ENTER_CLIENT]: Services.changeWorkspace,
  [Actions.GET_STATISTIC]: Services.getStatistic,
  [Actions.GET_FILES_STAT]: Services.getFilesStat,
  [Actions.DROP_COLLECTION]: Services.deleteCollection,
  [Actions.GET_FEATURES]: Services.getFeatures,
  [Actions.SWITCH_FEATURE]: Services.switchFeature,
  [Actions.CLONE_CLIENT]: Services.cloneClient,
  [Actions.ERASE_CLIENT]: Services.eraseClient,
  [Actions.GET_HANGED_FILES]: Services.getHangedFiles,
  [Actions.DEL_UNUSED_FILES]: Services.delUnusedFiles,
  [Actions.DEL_FILE]: Services.delFile,
  [Actions.GET_JOURNAL]: Services.getLogsJournal,
  [Actions.GET_THANKS]: Services.getThanks,
  [Actions.EXPORT]: Services.exportOne,
  [Actions.EXPORT_ALL]: Services.exportAll,
  [Actions.GET_BDAYS]: Services.getBirthdays,
  [Actions.GET_NOTIF_TEMPLATES]: Services.getNotifTemplates,
  [Actions.SAVE_NOTIF_TEMPLATE]: Services.saveNotifTemplates,
  [Actions.DEL_NOTIF_TEMPLATE]: Services.deleteNotifTemplate,
  [Actions.RUN_MAINTENANCE_TASK]: Services.runMaintananceTask,
  [Actions.EXEC_SENDSAY]: Services.execSendsay,
}

export default {
  state: store,
  getters,
  actions,
  mutations
};