import Vue from 'vue';
import App from './App.vue';

import router from './router/index';
import store from './store';

// import Antd from 'ant-design-vue';
import { Alert, Avatar, Button, Badge, Breadcrumb, Col, Collapse, Checkbox, DatePicker, Divider, Drawer, Empty, Dropdown, Form, FormModel, Icon, Input, InputNumber, List, Modal, Mentions, Menu, Pagination, Popconfirm, Popover, Progress, Rate, Radio, Result, Row, Select, Slider, Spin, Space, Steps, Switch, Table, Tabs, Tag, Timeline, TimePicker, Tooltip, Tree, TreeSelect, Upload, message, notification } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Alert)
Vue.use(Avatar)
Vue.use(Badge)
Vue.use(Breadcrumb)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(Collapse)
Vue.use(Col)
Vue.use(DatePicker)
Vue.use(Drawer)
Vue.use(Divider)
Vue.use(Dropdown)
Vue.use(Empty)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Icon)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(List)
Vue.use(Mentions)
Vue.use(Menu)
Vue.use(Modal)
Vue.use(Pagination)
Vue.use(Popconfirm)
Vue.use(Popover)
Vue.use(Progress)
Vue.use(Rate)
Vue.use(Radio)
Vue.use(Result)
Vue.use(Row)
Vue.use(Select)
Vue.use(Slider)
Vue.use(Space)
Vue.use(Spin)
Vue.use(Steps)
Vue.use(Switch)
Vue.use(Table)
Vue.use(Timeline)
Vue.use(Tag)
Vue.use(Tabs)
Vue.use(TimePicker)
Vue.use(Tooltip)
Vue.use(Tree)
Vue.use(TreeSelect)
Vue.use(Upload)
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$destroyAll = Modal.destroyAll;

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import 'vue-it-bigger/dist/vue-it-bigger.min.css'

import VueAxios from 'vue-axios';
import axios from 'axios';

// import ability from '../config/ability';
// import { abilitiesPlugin } from '@casl/vue';

import VueSocketIOExt from 'vue-socket.io-extended';

import { i18n } from '@/i18n'

import './assets/styles/global.styl';
import './assets/styles/global.scss';
//import CKEditor from '@ckeditor/ckeditor5-vue';
//import CKEditor from 'ckeditor4-vue';

import VueMq from 'vue-mq';
import VueClipboard from 'vue-clipboard2'
import VueSocialSharing from 'vue-social-sharing'
import InlineSvg from 'vue-inline-svg'

import { setAuthInterceptor } from '@/services/auth/authInterceptor';
import { setAuthToken } from '@/services/auth/setAuthToken';
// import GAuth from 'vue-google-oauth2';
import { getInfoUser } from '@/services/auth/auth.service';

import moment from 'moment';
import 'moment/locale/ru'
import 'moment/locale/uk'
import dayjs from './plugins/day.js';

import {ColorPicker, ColorPanel} from 'one-colorpicker';

// common components
import SvgIcon from '@/components/common/svg-icon.vue'
import SvgUse from '@/components/common/SvgUse.vue'

import MyButton from './components/common/Button.vue';
import MyInput from './components/common/Input.vue';
import Dialog from './components/common/Dialog.vue';
import ThePage from './components/common/blocks/ThePage.vue';
import Card from './components/common/blocks/Card.vue';
import MyCol from './components/common/blocks/Col.vue';
import MyRow from './components/common/blocks/Row.vue';
import MyAvatar from './components/common/avatar.vue';
import Loader from './components/common/blocks/Loader.vue';
import VueMeta from 'vue-meta'

import { initSocket, identifySocket } from './sockets';

// import * as Sentry from "@sentry/vue";
Vue.component('inline-svg', InlineSvg);
Vue.component('SvgIcon', SvgIcon);
Vue.component('SvgUse', SvgUse);

Vue.component('Avatar', MyAvatar)
Vue.component('Button', MyButton)
Vue.component('Card', Card)
Vue.component('Dialog', Dialog)
Vue.component('Input', MyInput)
Vue.component('Loader', Loader)
Vue.component('Page', ThePage)
Vue.component('Col', MyCol)
Vue.component('Row', MyRow)

// Sentry.init({
//   Vue: Vue,
//   dsn: "https://ad7bdd0b56134ad5bbffe10c46c502ee@o559915.ingest.sentry.io/5695137",
// });

// const gauthOption = {
//   clientId: '303964737986-rrtn3uic2fecmflemndjd8a1v4pjn76g.apps.googleusercontent.com',
//   scope: 'profile email https://www.googleapis.com/auth/calendar',
//   prompt: 'select_account',
// };
const api = process.env.NODE_ENV == 'development' ? 'http://localhost:4000' : location.origin
console.log('API URL:', api);

axios.defaults.baseURL = api;

//console.log(`ability: ${ability}`);
// Vue.use(GAuth, gauthOption);
Vue.use(VuePlyr);
Vue.use(VueMeta)
Vue.use(VueAxios, axios);

Vue.use(VueSocialSharing);
Vue.use(VueClipboard)
Vue.use(ColorPanel)
Vue.use(ColorPicker)

Vue.use(dayjs)

//Vue.use(CKEditor);

const socket = initSocket();

Vue.use(VueSocketIOExt, socket, { store });
Vue.prototype.$identifySocket = (client_id, user_id) => {
  identifySocket(client_id, user_id);
};;


Vue.config.productionTip = false;

setAuthToken(localStorage.getItem('token'));

if (localStorage.getItem('token')) {
  getInfoUser(localStorage.getItem('token'));
}

setAuthInterceptor(Vue, i18n);

Vue.use(VueMq, {
  breakpoints: {
    phone: 481,
    mobile: 769,
    tablet: 1025,
    mac: 1281,
    desktop: Infinity,
  },
});

Vue.use(require('vue-moment'), {
  moment
})

// TODO: прописать типы и сделать чтоб работало
// import Button from './components/common/Button'
// import DatePicker from './components/common/DatePicker'
// import Input from './components/common/Input'

// Vue.use(Button)
// Vue.use(DatePicker)
// Vue.use(Input)


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
