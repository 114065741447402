import Vue from 'vue';
import * as Mutations from '@/store/group/mutations.type';
import { GET_CURRENT_GROUP } from '@/store/group/actions.type';
// import { AxiosResponse } from 'axios';

export const createGroup = (context: any, group: any) => {
  return Vue.axios
    .post(`api/group/`, group)
    .then((response: any) => {
      context.commit(Mutations.ADD_GROUP, response.data.result);
      return response.data.result._id
    })
};

export const getGroups = (context: any, userId: any) => {
  return Vue.axios
    .get(`api/group/byUser/${userId}`)
    .then((response: any) => {
      context.commit(Mutations.SET_GROUPS, response.data.result);
    });
};

export const getGroupsByClient = (context: any, workspace: any) => {
  return Vue.axios
    .get(`api/group/byClient/${workspace}`)
    .then((response: any) => {
      context.commit(Mutations.SET_GROUPS, response.data.result);
    });
};
export const getCurrentGroup = (context: any, groupId: any) => {
  return Vue.axios
    .get(`api/group/${groupId}`)
    .then((response: any) => {
      if (response.data.status != 404){
        context.commit(Mutations.UPDATE_GROUP, response.data.result);
        context.commit(Mutations.SET_CURRENT_GROUP, response.data.result);
        return response.data.result;
      }
      else {
        context.commit(Mutations.SET_CURRENT_GROUP, null);
        return 404
      }
    });
};

export const deleteGroup = (context: any, groupId: any) => {
  return Vue.axios
    .delete(`api/group/${groupId}`)
    .then((response: any) => {
      context.commit(Mutations.REMOVE_GROUP, response.data.result);
    });
};

export const editGroup = (context: any, group: any) => {
  return Vue.axios
    .put(`api/group/${group._id}`, group)
    .then((response: any) => {
      context.commit(Mutations.UPDATE_GROUP, response.data.result);
    });
};

export const addGrantsUser = async (context: any, data: { userId: string, groupId: string}) => {
  const url = `api/group/${data.groupId}/grants/user/${data.userId}`
  const {data: grants} = await Vue.axios.post(url)
  context.commit(Mutations.SET_GROUP_GRANTS, grants);
}

export const delGrantsUser = async (context: any, data: { userId: string, groupId: string}) => {
  const url = `api/group/${data.groupId}/grants/user/${data.userId}`
  const {data: grants} = await Vue.axios.delete(url)
  context.commit(Mutations.SET_GROUP_GRANTS, grants);
}

export const replaceGroupsOwner = (context: any, data: any) => {
  return Vue.axios
    .post(`api/group/replace_owner/${data.userId}/${data.clientId}`);
};

export const changeGroupOwner = (context: any, data: any) => {
  return Vue.axios
    .put(`api/group/${data.groupId}/owner/${data.newOwner}`)
    .then((response: any) => {
      context.dispatch(GET_CURRENT_GROUP, data.groupId);
    });
};

// export const getRequests = async (context: any, userId: string) => {
//   const response = await Vue.axios.get(`api/group/requests/${userId}`)
//   context.commit(Mutations.SET_REQUESTS, response.data);
//   return response.data
// }

export const getGroupsThatUserCanRead = async (context: any, userId: string) => {
  const response = await Vue.axios.get(`api/group/can_read_by/${userId}`)
  context.commit(Mutations.SET_GROUPS_USER_CAN_READ, response.data);
  return response.data
}