import axios, { AxiosResponse, AxiosError } from 'axios';
import { SET_USER_DATA, ERROR_LOGIN } from '@/store/user/mutations.type';
import router from '@/router/index';
import store from '@/store';
import { translate as $t } from '@/i18n';

interface ErrorResponse {
  error?: string;
  no_show?: boolean;
  // другие возможные поля
}

export const setAuthInterceptor = (Vue, i18n) => {
  axios.interceptors.response.use(
    (response:AxiosResponse) => response, 
    (error: AxiosError<ErrorResponse>) => {
    if (error.response == undefined) {
      const cfg: any = error.config
      console.log(`${error.message} at ${cfg.method} ${cfg.url}`);
      return
    }
    const errData: any = error.response.data;
    console.log(error);
    if (error.response.status === 401) {
      localStorage.removeItem('authorization');
      //store.commit(SET_USER, null);
      store.commit(SET_USER_DATA, null); // для теста - чтобы убрать везде SET_USER_
      router.push('/login');
    } else if (error.response.status === 403) {
      console.error('Вход заблокирован')
      store.commit(ERROR_LOGIN, 'Вход заблокирован')
      return Promise.resolve(error)
    } else if (error.response.status === 404) {
      const data: any = error.response.data
      console.error('404 ERROR:', data)
      router.replace({ name: 'NotFound' });
    } else if (errData && errData.message) {
      const err = (errData.name ? errData.name + ': ' : '') + errData.message
      throw err
    } else if (axios.isAxiosError(error)) {
      const msg = error.response?.data?.error || error.message
      console.error(error.name + ': ' + msg )
      // показывать если не установлен флаг подавления (когда знаем что компонент корректно обработает ошибку )
      if (!error.response?.data?.no_show) Vue.prototype.$message.error($t(msg)) 
      throw msg
    }
    return Promise.reject(error);
  });
};
