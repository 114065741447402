import * as Actions from './actions.type'

import * as Mutations from './mutations.type'

import * as Service from './poll.service'

interface State {
  polls: any[];
  poll: any,
  poll_results: any;
}

const store: State = {
  polls: [],
  poll: null,
  poll_results: null,
};

// GETTERS
// const getters = {
//   polls(state: State) {
//     return state.polls;
//   },
//   poll(state: State) {
//     return state.poll;
//   },
// }

// ACTIONS
const actions = {
  //[CREATE_GROUP]: createGroup,
  [Actions.GET_POLLS]: Service.getPolls,
  [Actions.GET_POLL]: Service.getPoll,
  [Actions.NEW_POLL]: Service.newPoll,
  [Actions.SAVE_POLL]: Service.savePoll,
  [Actions.FINISH_POLL]: Service.finishPoll,
  [Actions.DELETE_POLL]: Service.deletePoll,
  [Actions.GET_POLL_RESULTS]: Service.getPollResults,
  [Actions.GET_POLL_EXCEL]: Service.getExcelResults,
}


const mutations = {
  // [ADD_GROUP](state: State, group: any) {
  //   state.groups = [group, ...state.groups];
  // },
  [Mutations.SET_POLLS](state: State, polls: any) {
    state.polls = polls;
  },
  [Mutations.SET_POLL](state: State, poll: any) {
    state.poll = poll;
  },
  [Mutations.SET_POLL_RESULTS](state: State, results: any) {
    state.poll_results = results;
  },
  [Mutations.REMOVE_POLL](state: State, _id: any) {
    state.polls = state.polls.filter(p => p._id != _id);
  },
}

export default {
  state: store,
  // getters,
  actions,
  mutations,
};
