<template>
  <div :class="{
    'f-align-center': !top && !between,
    'f-between-center': between,
    'f-align-baseline': baseline,
    'f-align-bottom': bottom,
    'f-align-top': top,
    'f-centered': centered,
    'flex-wrap': wrap,
    'w-100': full,
    [`gap${gap}`]: true,
  }"
  @click="$emit('click', $event)">
    <slot />
  </div>
</template>

<script>
export default {
  components: {

  },

  props: {
    gap: {
      type: [String, Number],
      default: '2'
    },
    between: { type: Boolean, default: false},
    baseline: { type: Boolean, default: false},
    top: { type: Boolean, default: false},
    bottom: { type: Boolean, default: false},
    centered: { type: Boolean, default: false},
    wrap: { type: Boolean, default: false},
    full: { type: Boolean, default: false},
  },
  
}
</script>

<style lang="scss">

</style>