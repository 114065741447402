import dayjs from "dayjs";
import 'dayjs/locale/ru'
import 'dayjs/locale/uk'

import minMax from 'dayjs/plugin/minMax'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import arraySupport from "dayjs/plugin/arraySupport"
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(arraySupport);
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(isToday)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(minMax)
dayjs.extend(utc)
dayjs.extend(timezone)

export const $d = dayjs

export default {
  install(Vue) {
    Vue.prototype.$dayjs = dayjs;
    // Vue.prototype.$date.prototype = dayjs.prototype;
    // Пример дополнительной функции расширяющей глобальный объект
    // Vue.prototype.$date.prototype.prettyDate = function(date) {
    //   date = dayjs(date);
    //   const diff = this.diff(date, "day");
    //   if (!diff) {
    //     return this.format("h mm A");
    //   } else if (diff === 1) {
    //     return `Yesterday, ${this.format("h mm A")}`;
    //   } else {
    //     return this.format("MMMM DD, YYYY h  mm A");
    //   }
    // };
  }
};