export const ADD_GROUP = 'ADD_GROUP';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';

// export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
// export const SET_PARTICIPANTS_REQUEST = 'SET_PARTICIPANTS_REQUEST';

// export const ADD_INVITE = 'ADD_INVITE';
// export const SET_INVITE = 'SET_INVITE';
// export const SET_INVITES = 'SET_INVITES';
// export const DELETE_INVITE = 'DELETE_INVITE'
export const SET_GROUP_GRANTS = 'SET_GROUP_GRANTS'

export const SET_REQUESTS = 'SET_REQUESTS';
export const SET_GROUPS_USER_CAN_READ = 'SET_GROUPS_USER_CAN_READ'
export const GROUP_REQUEST_DELETE = 'GROUP_REQUEST_DELETE'
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS'

