<template>
  <div v-if="noConnection" class="h-100 w-100 f-centered text-20 semibold" style="background-color: #bac5dd;">No connection to server!</div>
  <div v-else class="app">
    <AppNavbar v-if="currentClient && user" />
    <div class="main-container col">
      <AppHeader />
      <main v-if="userData && (currentClient || userIsSuperAdmin)" class="page-content">
        <router-view v-slot="{ Component }">
          <transition name="page" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </main>
      <Loader v-else />
      <FooterBlock />
      <MobileFooter v-if="$mq == 'phone'" />
      <MobileMainMenu v-if="$mq == 'phone'" />
      <MoodMeter v-if="!userIsSuperAdmin && needMeter && !hideMoodMeter" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SET_CURRENT_CLIENT } from '@/store/client/mutations.type'
import { ENTER_CLIENT } from '@/store/client/actions.type'
import { LOGOUT, GET_USERS, GET_INFO_ABOUT_ME } from "@/store/user/actions.type";
import { CHECK_METER_NEED } from "@/store/mood/actions.type";

import mxCoreData from '@/mixins/userData'

function plural(word, num) {
	const forms = word.split('_')
	return num % 10 === 1 && num % 100 !== 11
		? forms[0]
		: num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
		? forms[1]
		: forms[2]
}

function relativeTimeWithPlural(number, withoutSuffix, key) {
	const format = {
		ss: withoutSuffix ? 'секунда_секунды_секунд' : 'секунду_секунды_секунд',
		mm: withoutSuffix ? 'минута_минуты_минут' : 'минуту_минуты_минут',
		hh: 'час_часа_часов',
		dd: 'день_дня_дней',
		MM: 'месяц_месяца_месяцев',
		yy: 'год_года_лет',
	}
	if (key === 'm') {
		return withoutSuffix ? 'минута' : 'минуту'
	} else {
		return number + ' ' + plural(format[key], +number)
	}
}

function relativeTimeWithPluralUa(number, withoutSuffix, key) {
	const format = {
		ss: withoutSuffix ? 'секунда_секунди_секунд' : 'секунду_секунди_секунд',
		mm: withoutSuffix ? 'хвилина_хвилини_хвилин' : 'хвилину_хвилини_хвилин',
		hh: 'година_години_годин',
		dd: 'день_дня_днів',
		MM: 'місяць_місяца_місяців',
		yy: 'рік_року_років',
	}
	if (key === 'm') {
		return withoutSuffix ? 'хвилина' : 'хвилину'
	} else {
		return number + ' ' + plural(format[key], +number)
	}
}

export default {
	name: 'HomePage',
	components: {
		AppNavbar: () => import('@/components/common/blocks/NavBar2.vue'),
		AppHeader: () => import('@/components/common/blocks/Header.vue'),
		FooterBlock: () => import('@/components/common/blocks/Footer.vue'),
    MoodMeter: () => import('@/components/common/MoodMeter.vue'),
    MobileFooter: () => import('@/components/common/blocks/MobileFooter.vue'),
    MobileMainMenu: () => import('@/components/common/blocks/MobileMainMenu.vue'),
	},
	mixins: [ mxCoreData ],
	data() {
		return {
			noConnection: false,
		}
	},
	computed: {
		...mapGetters(['userData', 'currentClient', 'userIsAdmin', 'userIsSuperAdmin','needMeter']),
		collapsed() {
			return ['phone', 'mobile', 'tablet'].includes(this.$mq)
		},
		hideMoodMeter() {
			return this.currentClient.settings.hideMoodMeter
		},
		relativeMoments() {
			return {
				ru: {
					future: "через %s",
					past: "%s назад",
					s: "несколько секунд",
					ss: relativeTimeWithPlural,
					m: relativeTimeWithPlural,
					mm: relativeTimeWithPlural,
					h: "%d час",
					hh: relativeTimeWithPlural,
					d: "%d день",
					dd: relativeTimeWithPlural,
					M: "1 месяц",
					MM: relativeTimeWithPlural,
					y: "1 год",
					yy: relativeTimeWithPlural,
				},
				ua: {
					future: "через %s",
					past: "%s назад",
					s: "декiлька секунд",
					ss: relativeTimeWithPluralUa,
					m: relativeTimeWithPluralUa,
					mm: relativeTimeWithPluralUa,
					h: "%d час",
					hh: relativeTimeWithPluralUa,
					d: "%d день",
					dd: relativeTimeWithPluralUa,
					M: "1 мiсяць",
					MM: relativeTimeWithPluralUa,
					y: "1 рiк",
					yy: relativeTimeWithPluralUa,
				},
				
				en: {
					future: "in %s",
					past: "%s ago",
					s: "some seconds",
					ss: '%d second_second_seconds',
					m: "%d minute",
					mm: 'minuts_minute_minutes',
					h: "%d hour",
					hh: '%d hour_hour_hours',
					d: "%d day",
					dd: '%d day_day_days',
					M: "1 месяц",
					MM: '%d month_month_months',
					y: "1 год",
					yy: '%d year_year_years',
				}
			}
		},
	},
	// provide() {
	// 	console.log(`HOME provide`);
	// 	return {
	// 		workspace: () => {
	// 			return this.userIsSuperAdmin 
	// 				? (this.currentClient && this.currentClient.workspace) || ''
	// 				: this.userData?.client_id
	// 		},
	// 		hasRole: (role) => this.userData?.roles?.includes(role),
	// 		user: this.userData,
	// 		uid: this.userData?._id || "",
	// 		isAdmin: this.userIsAdmin,
  //     isSuperAdmin: this.userIsSuperAdmin,
	// 		apiURL: process.env.NODE_ENV == 'development' ? 'http://localhost:4000' : location.origin
	// 	}
	// },

	async created() {
		console.log(`== HOME created START`);
		window.onload = function() { // можно также использовать window.addEventListener('load', (event) => {
			// page loaded
		};
		try {
			const result = await this.axios.get('/api/env')
			if (!result) {
				this.noConnection = true
				console.log('Error of connection!');
				return 
			}
			const vendor = result.data.vendor
			console.log('VENDOR:', vendor);
			this.$store.state.common.vendor = vendor
			this.setFaviconSettings(vendor)
			document.title = vendor == 'yandex' ? 'Connectable' : 'Intranetable'
		} catch (error) {
			console.log('Error of gettings env from server!', error.message);
		}
		// console.log('NODE_ENV', process.env.NODE_ENV);
		// console.log('BASE_URL', process.env.BASE_URL);
		
		// console.log(`HOME: ${this.userIsSuperAdmin || 'no user_is_superadmin'}`);
		await this.GET_INFO_ABOUT_ME(); // получаем инфо о юзере
		const { _id, client_id, roles, lang:userLang } = this.userData

		const lang = userLang || this?.currentClient?.lang || 'ru'
		this.$dayjs.locale(lang == 'ua' ? 'uk' : lang)
		// console.log('Home: Локализация определена как', lang);
		this.$i18n.locale = lang
		this.$moment.updateLocale(lang == 'ua' ? 'uk' : lang, {
			relativeTime: this.relativeMoments[lang]
		})
		
		const isSuperAdmin = roles.includes('superadmin')
		// console.log('Home.created: user is', user);
		if (!this.currentClient) {
			if (!isSuperAdmin) {
				await this.ENTER_CLIENT(client_id)
			}
			else {
				const currentClient = JSON.parse(localStorage.getItem('currentClient'))
				if (currentClient) this.SET_CURRENT_CLIENT(currentClient)
			}
		}

		// проверяем локально сохраненную стартовую страницу и если не такая как в настройках, то сохраняем и переходим
		let start = this.currentClient?.start_page || 'company';
		if (start == 'profile') start = `${start}/${_id}`
		if (start != localStorage.getItem('start_page')) {
			localStorage.setItem('start_page', start);
		}

		const wsp = this.userIsSuperAdmin
			? (this.currentClient?.workspace || '')
			: client_id
		
		await this.GET_USERS(wsp);
		
    if (!this.userIsSuperAdmin) {
			this.CHECK_METER_NEED(_id)
		}
		// Connect
		if (wsp) {
			this.$socket.client.nsp = '/'+wsp;
			if (!this.$socket.connected) {
				console.log('Home - socket connecting');
				// this.$socket.client.disconnect();
				this.$socket.client.connect(`${this.apiURL}`);
			}
			this.$identifySocket(wsp, _id) // отправляем на сервер информацию о юзере и клиенте
		}
	},

	methods: {
		...mapActions([GET_USERS, ENTER_CLIENT, LOGOUT, GET_INFO_ABOUT_ME, CHECK_METER_NEED]),
		...mapMutations([SET_CURRENT_CLIENT]),
		setFaviconSettings(vendor) {
			const path = document.location.origin + (vendor == 'yandex' ? '/connectable/' : '/intranetable/')
			const icon = document.getElementById('favicon');
			icon.href = document.location.origin + (vendor == 'yandex' ? '/fav_conn.ico' : '/fav_intra.ico');
			
			const appleLink = document.createElement('link');
			appleLink.rel = 'apple-touch-icon';
			appleLink.sizes = '180x180'
			appleLink.href = path + 'apple-touch-icon.png';
			const icon32 = document.createElement('link');
			icon32.rel = 'icon'
			icon32.type = 'image/png'
			icon32.sizes = '32x32'
			icon32.href = path + 'favicon-32x32.png';
			const icon16 = document.createElement('link');
			icon16.rel = 'icon'
			icon16.type = 'image/png'
			icon16.sizes = '16x16'
			icon16.href = path + 'favicon-16x16.png';
			const manifest = document.createElement('link');
			manifest.rel = 'manifest'
			manifest.href = path + 'site.webmanifest';
			const mask = document.createElement('link');
			mask.rel = 'mask-icon'
			mask.color = '#5bbad5'
			mask.href = path + 'safari-pinned-tab.svg';
			document.head.appendChild(icon);
			document.head.appendChild(appleLink);
			document.head.appendChild(icon32);
			document.head.appendChild(icon16);
			document.head.appendChild(manifest);
			document.head.appendChild(mask);
		}
	},

	sockets: {
		// async backendMessage(payload) {
		// 	// обработчики для приложения в целом, без привязки к любым компонентам
		// 	// console.log('HOME Socket listenter:', payload);
		// },
		connect() {
      // this.lastPong = new Date();
      console.log("socket connected:", this.$socket.client.id);
      this.$identifySocket(this.workspace, this.uid)
    },
    connect_error(err) {
      console.log(`Connection error...`, err);
    },
    disconnect() {
      // this.pong.type = "error";
      // this.pong.msg = "Connection lost";
      //this.connModal = { visible: true, connected: false };
      console.log('socket disconnected!');
    },
	},

}
</script>

<style lang="scss" scoped>
.app {
	display: flex;
  flex: 1;
  min-height: 100vh;
	width: 100%;
	.main-container {
		flex: 1;
		overflow-y: auto; // чтобы прокручивалось вместе с шапкой
		main {
			flex: 1;
			// overflow-y: au÷to; // чтобы прокручивалось без шапки
		}
	}
}
</style>

<style lang="stylus" scoped>
@import '~@/assets/styles/app'
@import '~@/assets/styles/vars'
.page-content
		display flex
		flex 1 1 auto
	@media (max-width: $phone)
		height calc(100vh - 100px)
		margin-top 50px
</style>

<style lang="scss">
pre {
	font-family: 'Fira Code', 'Consolas', monospace !important;
	font-size: 9pt !important;
}
.ant-checkbox-group {
	display: block !important;
}

.open-action-button {
	background-color: transparent !important;
	border: 0 !important;
}
.ant-drawer {
	z-index: 1031 !important;
}

.action-popup-content {
	.anticon {
		margin: 0 0.6rem !important;
		cursor: pointer !important;
	}
}

.ant-form-item.no-details {
	margin-bottom: 0;
}

.ant-page-header.page-header {
	@media (max-width: 767px) {
		padding: 0;
	}
}

.home {
	display: flex;
	justify-content: flex-end;
	height: 100vh;

	.content {
		width: calc(100vw - 12.5rem);
		overflow: auto;
		background-color: #f0f0f7;

		@media (max-width: 1024px) {
			width: calc(100vw - 4rem);
		}
	}
}
</style>
