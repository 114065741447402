import Vue from 'vue';

import {
  SET_POLLS, 
  SET_POLL,
  REMOVE_POLL,
  SET_POLL_RESULTS,
} from './mutations.type'
import { AxiosError } from 'axios';

/**
 * Объединенная функция отправки запроса и изменения состояния 
 * @param url Адрес API
 * @param data Данные для передачи
 * @param context просто прокидывание 
 * @param mutation мутация
 * @returns результат запроса (response.data.result)
 */
 async function GetAndUpdate(url: string, context:any, mutation: string) {
  try {
    const response = await Vue.axios.get(url)  
    context.commit(mutation, response.data.result);
    return response.data.result
  } catch (error) {
    const err = error as AxiosError
    if (err.response) {
      // 4**, 5** - Request made but the server responded with an error
      console.log('Error from server:');
      console.log(err.response.data);
      console.log(err.response.status);
      throw err.response.data
    } else if (err.request) {
      // Запрос сделан, но ответ от сервера не получен
      console.log('No response:', err.request);
      throw 'No response from server'
    } else if (typeof err == 'string') {
      // Ошибка до отправки запроса
      console.log('Error string:', err);
      throw err
    } else {
      console.log('Error message:', err);
      throw err.message
    }
  }
  
}
async function PutAndUpdate(url: string, data: any, context:any, mutation: string) {
  const response = await Vue.axios.put(url, data)
  context.commit(mutation, response.data.result);
  return response.data.result
}
async function PostAndUpdate(url: string, data: any, context:any, mutation: string) {
  const response = await Vue.axios.post(url, data)
  context.commit(mutation, response.data.result);
  return response.data.result
}
async function DeleteAndUpdate(url: string, data: any, context:any, mutation: string) {
  const response = await Vue.axios.delete(url, data)
  context.commit(mutation, response.data.result);
  return response.data.result
}


export const getPolls = async (context: any, data: {clientId: string, userId: string}) => {
  // return await GetAndUpdate(`api/poll/for_user/${data.userId}`, context, SET_POLLS)
  return await GetAndUpdate(`api/user/${data.clientId}/polls/access_for/${data.userId}`, context, SET_POLLS)
};

// получение одного опроса
export const getPoll = async (context: any, pollId: any) => {
  return await GetAndUpdate(`api/poll/${pollId}`, context, SET_POLL)
};

export const newPoll = async (context: any, poll: any) => {
  return await PostAndUpdate(`api/poll`, poll, context, SET_POLL)
};

export const savePoll = async (context: any, poll: any) => {
  return await PutAndUpdate(`api/poll/${poll._id}`, poll, context, SET_POLL)
};

type AnswersData = {
  _id: string,
  user: string,
  answers: any[]
}

export const finishPoll = async (context: any, data: AnswersData) => {
  return await PutAndUpdate(`api/poll/${data._id}/finish`, data, context, SET_POLL)
};

/**
 * Получение результатов опроса с доп. данными
 * @param context 
 * @param data 
 * @returns 
 */
export const getPollResults = async (context: any, pollId: string) => {
  return await GetAndUpdate(`api/poll/${pollId}/results`, context, SET_POLL_RESULTS)
};


export const deletePoll = (context: any, _id: string) => {
  // пока не подходит этот вариант, надо переписать
  // return DeleteAndUpdate(`api/poll/${_id}`, context, REMOVE_POLL, _id)
  return Vue.axios
    .delete(`api/poll/${_id}`)
    .then((response: any) => {
      return context.commit(REMOVE_POLL, _id);
    })
};

export const getExcelResults = async (context: any, data: {poll_id: string, user_id:string}) => {
  const res = await Vue.axios.get(`api/poll/${data.poll_id}/excel?uid=${data.user_id}`, { responseType: 'blob' })
  return res.data
}