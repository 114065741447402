<template>
  <div
    :class="['col', `gap${gap}`, {
      'f-align-center': center, 
      'f-centered': centered, 
      'flex-between': between,
      'w-100': full
    }]"
    :style="{width}"
  >
    <slot />
  </div>
</template>

<script>
export default {
  components: {

  },

  props: {
    gap: {
      type: [String, Number],
      default: '2'
    },
    center: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    between: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  
}
</script>

<style lang="scss">

</style>