import * as Mutations from "./mutations.type";
import * as Actions from "./actions.type";
import * as Service from "./structure.service";

interface State {
	structure: any | null; // новая структура
	dept_users: any | null; // сотрудники отделов клиента
	dialogEditInfo: boolean; // показ диалога изменения названия или создания отдела
	dialogDeptCard: boolean; // показ диалога всей инфы об отделе
	activeItem: string;
}

const store: State = {
	structure: null,
	dept_users: null,
	dialogEditInfo: false,
	dialogDeptCard: false,
	activeItem: "",
};

// получение всех отделов
const getDepts = (dept) =>
	dept.children?.length
		? dept.children.reduce((acc, ch) => ({ ...acc, ...getDepts(ch) }), { [dept.id]: dept.name })
		: { [dept.id]: dept.name };

const getters = {
	structure(state: State) {
		return state.structure;
	},
	structures(state: State) {
		if (!state.structure) return null;
		const res = {};
		Object.entries(state.structure).forEach(([key, structure]) => {
			if (key.substring(0, 7) == "orgTree") res[key] = getDepts(structure);
		});
		return res;
	},
	dept_users(state: State) {
		return state.dept_users || [];
	},
	D3data(state: State) {
		const plain = (el, parentId = undefined) =>
			[
				{
					id: el.id,
					name: el.name,
					description: el.description,
					parentId,
				},
				el.children ? [...el.children.map((i) => plain(i, el.id))] : [],
			].flat(10);

		const res = {};
		if (!state.structure) return res;
		Object.entries(state.structure).forEach(([key, structure]) => {
			if (key.substring(0, 7) == "orgTree") {
				const plainData = plain(structure);
				res[key] = plainData.map((d) => ({ ...d, dept_info: state?.dept_users?.find((du) => du.dept_id == d.id) }));
			}
		});

		return res;
	},
  userDepts(state: State, getters, rootState) {
    return state.dept_users?.filter(du => du.users.includes(rootState.auth.userData._id))
  },
	// множество начальников для проверки является им или нет
	chiefsSet(state: State, getters, rootState) {
		const heads = state.dept_users?.flatMap(du => du.headUser)
		const cust = rootState.auth.users.filter(u => u.chief).map(u => u.chief)
		return new Set([...heads, ...cust])
	}
};

const mutations = {
	[Mutations.SET_STRUCTURE](state: State, structure: any) {
		state.structure = structure;
	},
	[Mutations.SET_DEPT_USERS](state: State, data: any) {
		state.dept_users = data;
	},
	[Mutations.ADD_DEPT_USERS](state: State, data: any) {
		state.dept_users = [...state.dept_users, data];
	},
	// переписать для обновления одного сотрудника а не всей записи
	[Mutations.UPDATE_DEPT_USER](state: State, data: any) {
		if (!state.dept_users) state.dept_users = [data];
		if (!state.dept_users.some((it) => it._id == data._id)) state.dept_users = [...state.dept_users, data];
		else state.dept_users = state.dept_users.map((el: any) => (el._id == data._id ? data : el));
	},
	// переписать под удаление одного сотрудника, а не всей записи
	[Mutations.REMOVE_DEPT_USER](state: State, id: any) {
		// const i = state.dept_users.findIndex((el: any) => el._id = id)
		state.dept_users = state.dept_users.filter((el) => (el._id = id));
	},
	// ex: this.DIALOG({type: 'edit', show: false})
	[Mutations.DIALOGS](state: State, data: { type: "edit" | "full"; show: boolean }) {
		switch (data.type) {
			case "edit":
				state.dialogEditInfo = data.show;
				break;
			case "full":
				state.dialogDeptCard = data.show;
				break;
			default:
				break;
		}
	},
	[Mutations.SET_ACTIVE_ITEM](state: State, id: string) {
		state.activeItem = id;
	},
};

const actions = {
	[Actions.GET_STRUCTURE]: Service.getStructure,
	[Actions.EDIT_STRUCTURE]: Service.editStructure,
	[Actions.SAVE_STRUCTURE]: Service.saveStructure,
	[Actions.GET_DEPT_USERS]: Service.getDepartmentUsers,
	[Actions.SAVE_DEPT_USERS]: Service.saveUsersOfDepartment,
	[Actions.EDIT_DEPT_USER]: Service.editUserOfDepartment,
	[Actions.DELETE_DEPT_USER]: Service.delUserOfDepartment,
	[Actions.CLEAR_HEAD_OF_DEPTS]: Service.clearHeadOfDepartaments,
};

export default {
	state: store,
	getters,
	actions,
	mutations,
};
