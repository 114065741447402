import io from 'socket.io-client';

const api = process.env.NODE_ENV == 'development' ? 'http://localhost:4000' : location.origin

let socket = null;

export const initSocket = () => {
  if (!socket) {
    socket = io(api, {
      reconnection: true,
      reconnectionDelay: 1000,
      timeout: 1000 * 60 * 20,
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      console.log('✅ Socket connected');
    })

    socket.on('connect_error', err => {
      console.log('❌ Error of socket connection:', err.message);
    })
  }
  return socket;
};

export const getSocket = () => {
  if (!socket) {
    console.log('Socket not initialized');
    return initSocket();
  }
  return socket;
};

export const identifySocket = (client_id, user_id) => {
  const socket = getSocket();
  
  // Отправляем идентификационную информацию на сервер
  socket.emit('identify', {
    client_id,
    user_id,
    timestamp: Date.now()
  });
  
  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    // Отправляем серверу событие о разлогинивании
    socket.emit('user-logout');
    
    // Закрываем соединение
    socket.disconnect();
    
    // Сбрасываем ссылку на сокет
    socket = null;
    
    console.log('Socket disconnected due to user logout');
    return true;
  }
  return false;
};